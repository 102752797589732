<template>
  <div>
      <ProductivityReportTable />
  </div>
</template>

<script>
import ProductivityReportTable from '@/components/Dashboard/ProductivityReport/ProductivityReportTable'
export default {
  components: {
    ProductivityReportTable
  }
}
</script>

<style>

</style>