<template>
  <div class="p-6 bg-white rounded-xl shadow-lg mb-4">
    <div class="flex">
      <img src="@/assets/icons/search-grey.svg" class="h-3 my-auto mr-2" />
      <h2 class="text-left text-md text-gray-600">
        <b>Filtros de búsqueda</b>
      </h2>
    </div>
    <div class="flex flex-row gap-2" @keypress.enter="onSearch">
      <div
        class="w-full grid sm:grid-cols-2 lg:grid-cols-5 justify-between gap-6"
      >
        <BaseSelect
          label="Tipo de identificación"
          :selectOptions="formOptions.optionsIdentificationType"
          v-model="form.identification_type"
        />
        <BaseInput
          type="text"
          label="Número de identificación"
          placeholder="Ej: 1144578546"
          v-model="form.identification_number"
          :identification="form.identification_type"
        />
        <BaseInput
          type="text"
          label="Nombre"
          placeholder="Escribe el nombre del responsable"
          v-model="form.fullname"
        />
        <BaseSelect
          label="Entidad"
          :selectOptions="optionsCompanies"
          v-model="form.company"
        />
        <button
          v-if="!loading"
          @click="onSearch"
          class="rounded-md h-10 lg:max-w-max mt-auto hover:bg-blue-500 bg-blue-600 px-3"
          :disabled="disabled"
        >
          <img
            src="@/assets/icons/lens.svg"
            class="h-5 my-auto mr-2"
            style="width: 100%"
          />
        </button>

        <div v-if="loading" class="mt-auto w-full xl:w-10">
          <a-spin>
            <a-icon
              slot="indicator"
              type="loading"
              style="font-size: 24px"
              spin
            />
          </a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["loading", "query"],
  data() {
    return {
      form: {
        fullname: "",
        identification_type: "",
        identification_number: "",
        company: "",
      },
    };
  },
  methods: {
    onSearch() {
      this.$emit("fetch-productivity", this.form);
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    optionsCompanies: function () {
      return this.$store.state.form?.optionsCompanies?.filter(
        (item) => item?.direction == this.$store.state?.session?.user.direction
      );
    },
    disabled: function () {
      return false;
    },
  },
  mounted() {
    this.form = { ...this.$props.query };
  },
};
</script>

<style></style>
